import { Button } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.svg";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div
            className="d-flex align-items-center justify-content-start"
            style={{ height: "60vh" }}
        >
            <div className="mx-auto text-center">
                <div className="p-0">
                    <img
                        src={logo}
                        width={300}
                        className="mx-auto"
                        style={{ transform: "scale(1.15)" }}
                        alt="logo-page alt"
                    />
                </div>
                <h2 style={{ marginTop: "-30px" }}>Page not found</h2>
                <p className="mt-3">
                    Sorry, the page you are looking for could not be found or
                    has been removed.
                </p>

                <Button
                    style={{ background: "#0054a3" }}
                    className="px-3 py-2"
                    onClick={() => navigate("/", { replace: true })}
                >
                    <FiArrowLeft size={24} /> &nbsp;
                    <span>Back to Homepage</span>
                </Button>
            </div>
        </div>
    );
};

export default NotFoundPage;
