export const saveLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const removeItemLocalStorage = (key) => {
    localStorage.removeItem(key);
};
