// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
    color: #0054a3;
    text-decoration: underline;
    text-decoration-color: #0054a3;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,0BAA0B;IAC1B,8BAA8B;IAC9B,8BAA8B;IAC9B,2BAA2B;AAC/B","sourcesContent":[".selected {\n    color: #0054a3;\n    text-decoration: underline;\n    text-decoration-color: #0054a3;\n    text-decoration-thickness: 2px;\n    text-underline-offset: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
