import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import "./SearchBox.css";

const SearchBox = (props) => {
    // props
    const { handleOnEnterSearchBox, recommendSearchData } = props;

    // state
    const onTagsChange = (event, values) => {
        const tags = values
            .filter((item) => item.type === "Tags")
            .map((item) => item.text);
        const authors = values
            .filter((item) => item.type === "Author")
            .map((item) => item.text);
        const models = values
            .filter((item) => item.type === "ModelName")
            .map((item) => item.text);
        const benchmarks = values
            .filter((item) => item.type === "Benchmark")
            .map((item) => item.text);

        handleOnEnterSearchBox(tags, authors, models, benchmarks);
    };

    return (
        <div>
            <div className="row search-container mt-3 mb-md-3 mb-sm-2 mb-2 px-lg-3 px-md-2 px-sm-1 px-0">
                {/* Search bar */}
                <Stack spacing={2} sx={{ width: "100%" }}>
                    <Autocomplete
                        multiple
                        limitTags={3}
                        id="standard-multi-search"
                        options={recommendSearchData.sort((a, b) =>
                            a.title.localeCompare(b.title)
                        )}
                        getOptionLabel={(option) => option.title}
                        defaultValue={[]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Search"
                                placeholder="Search"
                            />
                        )}
                        onChange={onTagsChange}
                    />
                </Stack>
            </div>
        </div>
    );
};

export default SearchBox;
