import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import HeaderLayer from "./components/layout/HeaderLayer";
import NavbarLayer from "./components/layout/NavbarLayer";
import OverviewPage from "./views/OverviewPage";
import DataPage from "./views/DataPage";
import SubmissionPage from "./views/SubmissionPage/SubmissionPage";
import DatasetContextProvider from "./contexts/DatasetContext";
import LeaderboardContextProvider from "./contexts/LeaderboardContext";
import SubmissionContextProvider from "./contexts/SubmissionContext";
import LeaderboardPage from "./views/LeaderboardPage/LeaderboardPage";
import NotFoundPage from "./views/NotFoundPage";

function App() {
    return (
        <div className="mx-xl-5 mx-lg-5 mx-md-3 mx-sm-2 mx-2">
            <BrowserRouter>
                <HeaderLayer />
                <NavbarLayer />
                <Routes>
                    <Route path="/" element={<OverviewPage />} />
                    <Route path="/home" element={<Navigate to="/" />} />
                    <Route
                        path="/data"
                        element={
                            <DatasetContextProvider>
                                <DataPage />
                            </DatasetContextProvider>
                        }
                    />
                    <Route
                        path="/submission"
                        element={
                            <SubmissionContextProvider>
                                <SubmissionPage />
                            </SubmissionContextProvider>
                        }
                    />
                    <Route
                        path="/leaderboard"
                        element={
                            <LeaderboardContextProvider>
                                <LeaderboardPage />
                            </LeaderboardContextProvider>
                        }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>

                {/* Toast to show notification */}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </BrowserRouter>
        </div>
    );
}

export default App;
