import axios from "axios";
import * as Config from "../constants/Config"

// eslint-disable-next-line
export default {
    getDatasetDescription: function (task) {
        try {
            const params = {task: task};
            const response = axios({
                method: "GET",
                url: Config.DATASET_DESCRIPTION_URL,
                params: params
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getBenchmarkDataset: function (task, benchmarkName) {
        try {
            const params = {task: task, benchmark_name: benchmarkName};
            const response = axios({
                method: "GET",
                url: Config.GET_BENCHMARK_DATASET_URL,
                params: params
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getAllBenchmarkNames: function (task) {
        try {
            const params = {task: task};
            const response = axios({
                method: "GET",
                url: Config.ALL_BENCHMARK_NAMES_URL,
                params: params
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateSemanticSimilarity: function (supportedBenchmark, predictResult) {
        try {
            const params = {supported_benchmark: supportedBenchmark};
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.STS_BENCHMARK_URL,
                params: params,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateSemanticSimilarityDirectly: function (predictResult) {
        try {
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.STS_BENCHMARK_DIRECTLY_URL,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateTextClassification: function (supportedBenchmark, predictResult) {
        try {
            const params = {supported_benchmark: supportedBenchmark};
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.TEXT_CLS_BENCHMARK_URL,
                params: params,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateTextClassificationDirectly: function (predictResult) {
        try {
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.TEXT_CLS_BENCHMARK_DIRECTLY_URL,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateTokenClassification: function (supportedBenchmark, predictResult) {
        try {
            const params = {supported_benchmark: supportedBenchmark};
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.TOKEN_CLS_BENCHMARK_URL,
                params: params,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    evaluateTokenClassificationDirectly: function (predictResult) {
        try {
            const headers = {"Content-Type": "multipart/form-data"};
            const formData = new FormData();
            formData.append("predict_result", predictResult);
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.TOKEN_CLS_BENCHMARK_DIRECTLY_URL,
                data: formData,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getAllSubmissions: function (task) {
        try {
            const params = {task: task};
            return axios({
                method: "GET",
                url: Config.GET_ALL_SUBMISSION_URL,
                params: params
            });
        } catch (error) {
            throw error;
        }
    },

    addSubmission: function (body) {
        try {
            const headers = {"Authorization": `Bearer ${process.env.REACT_APP_TOKEN_AUTHENTICATION}`};
            const response = axios({
                method: "POST",
                headers: headers,
                url: Config.ADD_SUBMISSION_URL,
                data: body,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },


}