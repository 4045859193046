export const NONE_TASK = "none";
export const SEMANTIC_TEXTUAL_SIMILARITY_TASK = "semantic_textual_similarity";
export const TEXT_CLASSIFICATION_TASK = "text_classification";
export const TOKEN_CLASSIFICATION_TASK = "token_classification";

export const EVALUATION_SUBMIT = "evaluation_submit";
export const RESULT_SUBMIT = "result_submit";

// for local storage saving
export const LOCAL_STORAGE_TASK = "LOCAL_STORAGE_TASK";
export const LOCAL_STORAGE_BENCHMARK = "LOCAL_STORAGE_BENCHMARK";
export const LOCAL_STORAGE_FULLNAME_PATH = "LOCAL_STORAGE_FULLNAME_PATH";

// For checking health
export const HEALTH_CHECKING_PATHNAME = "/api/healthcheck";
export const HEALTH_CHECKING_MESSAGE = {
    status: "ok",
    message: "Application is healthy",
};
