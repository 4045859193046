import { useState } from "react";

const PaginationLayer = ({
    currentPage,
    totalCards,
    setCurrentPage,
    setCardsPerPages,
    className,
}) => {
    const [pageSize, setPageSize] = useState(5);
    const totalPages = Math.ceil(totalCards / pageSize);

    let pageOptions = [];
    if (totalPages < 5) {
        pageOptions = [];
        for (let i = 1; i <= totalPages; i++) pageOptions.push(i);
    } else {
        pageOptions = [];
        if (currentPage < 3) {
            pageOptions.push(...[1, 2, 3]);
            pageOptions.push("...");
            pageOptions.push(totalPages);
        } else if (currentPage > totalPages - 2) {
            pageOptions.push(1);
            pageOptions.push("...");
            pageOptions.push(...[totalPages - 2, totalPages - 1, totalPages]);
        } else {
            pageOptions.push(1);
            pageOptions.push("...");
            pageOptions.push(
                ...[currentPage - 1, currentPage, currentPage + 1]
            );
            pageOptions.push("...");
            pageOptions.push(totalPages);
        }
    }

    const gotoPage = (idx) => {
        setCurrentPage(idx);
    };
    const previousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
    const nextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    return (
        <div className={className}>
            <span className="page-count">
                Page <b>{currentPage}</b> of <b>{totalPages}</b>
            </span>
            <select
                className="select-size"
                value={pageSize}
                onChange={(e) => {
                    let idx = e.target.value;
                    setCurrentPage(1);
                    setPageSize(Number(idx));
                    setCardsPerPages(idx);
                }}
            >
                {[5, 10, 15].map((pSize) => (
                    <option key={pSize} value={pSize}>
                        {pSize} / page
                    </option>
                ))}
            </select>

            <ul className="pagination pagination-md" style={{ margin: 0 }}>
                <li className="page-item" onClick={() => gotoPage(1)}>
                    <span className="page-link">&laquo;</span>
                </li>
                <li className="page-item" onClick={() => previousPage()}>
                    <span className="page-link">&lsaquo;</span>
                </li>
                {pageOptions.map((value) => {
                    if (typeof value === "string" && value.includes("...")) {
                        return (
                            <li className="page-item" key={value}>
                                <span className="page-link">{value}</span>
                            </li>
                        );
                    } else {
                        return (
                            <li
                                className="page-item"
                                key={value}
                                onClick={() => gotoPage(value)}
                            >
                                <span
                                    className={
                                        "page-link" +
                                        (currentPage === value
                                            ? " page-active"
                                            : "")
                                    }
                                >
                                    {value}
                                </span>
                            </li>
                        );
                    }
                })}
                <li className="page-item" onClick={() => nextPage()}>
                    <span className="page-link">&rsaquo;</span>
                </li>
                <li className="page-item" onClick={() => gotoPage(totalPages)}>
                    <span className="page-link">&raquo;</span>
                </li>
            </ul>
        </div>
    );
};

export default PaginationLayer;
