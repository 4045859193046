import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    HEALTH_CHECKING_MESSAGE,
    HEALTH_CHECKING_PATHNAME,
} from "./all_contants";

const root = ReactDOM.createRoot(document.getElementById("root"));
if (window.location.pathname !== HEALTH_CHECKING_PATHNAME)
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
else root.render(JSON.stringify(HEALTH_CHECKING_MESSAGE));

reportWebVitals();
