import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import Collapse from "react-bootstrap/Collapse";
import { forwardRef, useImperativeHandle } from "react";

const MetadataFormStep3 = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        checkValidInput() {
            let checkerValid = true;
            if (authorName.length === 0) {
                setValidAuthorName(true);
                checkerValid = false;
            } else setValidAuthorName(false);

            if (modelName.length === 0) {
                setValidModelName(true);
                checkerValid = false;
            } else setValidModelName(false);

            if (submissionTag.length === 0) {
                setValidSubmissionTag(true);
                checkerValid = false;
            } else {
                const arrTags = submissionTag
                    .split(",")
                    .map((item) => item.trim())
                    .filter((item) => item.length > 0);

                let validTagCheck = true;
                for (let item of arrTags) {
                    if (item.length === 0 || !item.startsWith("#")) {
                        setValidSubmissionTag(true);
                        checkerValid = false;
                        validTagCheck = false;
                        break;
                    }
                }

                if (validTagCheck) {
                    setValidSubmissionTag(false);
                }
            }

            if (
                !(
                    url.length === 0 ||
                    url.startsWith("https") ||
                    url.startsWith("http")
                )
            ) {
                setValidUrl(true);
                checkerValid = false;
            } else setValidUrl(false);

            if (
                !(
                    urlRepo.length === 0 ||
                    urlRepo.startsWith("https") ||
                    urlRepo.startsWith("http")
                )
            ) {
                setValidUrlRepo(true);
                checkerValid = false;
            } else setValidUrlRepo(false);

            if (
                !(
                    urlModel.length === 0 ||
                    urlModel.startsWith("https") ||
                    urlModel.startsWith("http")
                )
            ) {
                setValidUrlModel(true);
                checkerValid = false;
            } else setValidUrlModel(false);

            if (
                !(
                    urlDataset.length === 0 ||
                    urlDataset.startsWith("https") ||
                    urlDataset.startsWith("http")
                )
            ) {
                setValidUrlDataset(true);
                checkerValid = false;
            } else setValidUrlDataset(false);

            return checkerValid;
        },

        getMetadata() {
            return {
                author: authorName,
                model: modelName,
                tags: submissionTag
                    .split(",")
                    .map((item) => item.trim())
                    .filter((item) => item.length > 0 && item.startsWith("#")),
                url: url,
                urlRepo: urlRepo,
                urlModel: urlModel,
                urlDataset: urlDataset,
            };
        },

        updateTrueUploadStatus() {
            setUploadState(true);
        },

        updateFalseUploadStatus() {
            setUploadState(false);
        },

        resetAll() {
            setOpenMetadata(false);
            setUploadState(false);

            setAuthorName("");
            setModelName("");
            setSubmissionTag("");
            setUrl("");
            setUrlRepo("");
            setUrlModel("");
            setUrlDataset("");

            setValidAuthorName(false);
            setValidModelName(false);
            setValidSubmissionTag(false);
            setValidUrl(false);
            setValidUrlRepo(false);
            setValidUrlModel(false);
            setValidUrlDataset(false);
        },
    }));

    // State
    const [isOpenMetadata, setOpenMetadata] = useState(false);
    const [uploadState, setUploadState] = useState(false);

    // author name form state
    const [authorName, setAuthorName] = useState("");
    const [modelName, setModelName] = useState("");
    const [submissionTag, setSubmissionTag] = useState("");
    const [url, setUrl] = useState("");
    const [urlRepo, setUrlRepo] = useState("");
    const [urlModel, setUrlModel] = useState("");
    const [urlDataset, setUrlDataset] = useState("");

    const [validAuthorName, setValidAuthorName] = useState(false);
    const [validModelName, setValidModelName] = useState(false);
    const [validSubmissionTag, setValidSubmissionTag] = useState(false);
    const [validUrl, setValidUrl] = useState(false);
    const [validUrlRepo, setValidUrlRepo] = useState(false);
    const [validUrlModel, setValidUrlModel] = useState(false);
    const [validUrlDataset, setValidUrlDataset] = useState(false);

    const onChangeAuthorName = (event) => {
        setAuthorName(event.target.value);
    };

    const onChangeModelName = (event) => {
        setModelName(event.target.value);
    };

    const onChangeSumissionTag = (event) => {
        setSubmissionTag(event.target.value);
    };

    const onChangeUrl = (event) => {
        setUrl(event.target.value);
    };

    const onChangeUrlRepo = (event) => {
        setUrlRepo(event.target.value);
    };

    const onChangeUrlModel = (event) => {
        setUrlModel(event.target.value);
    };

    const onChangeUrlDataset = (event) => {
        setUrlDataset(event.target.value);
    };

    const clickOpenMetadata = () => {
        setOpenMetadata(!isOpenMetadata);
    };

    return (
        <div className="mt-3">
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                        <Form.Label>Author Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Author Name"
                            style={{ boxShadow: "none" }}
                            value={authorName}
                            onChange={onChangeAuthorName}
                            isInvalid={validAuthorName}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide author name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6">
                        <Form.Label>Model Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Model Name"
                            style={{ boxShadow: "none" }}
                            value={modelName}
                            onChange={onChangeModelName}
                            isInvalid={validModelName}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide model name.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                        <Form.Label>
                            <span>Submission Tags</span> &nbsp;
                            <small
                                className="text-decoration-underline"
                                style={{
                                    fontStyle: "italic",
                                    fontWeight: "bold",
                                }}
                            >
                                (Examples: #fschool, #history, #fqa, ...)
                            </small>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Submission Tags"
                            style={{ boxShadow: "none" }}
                            value={submissionTag}
                            onChange={onChangeSumissionTag}
                            isInvalid={validSubmissionTag}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide submission tags or check the valid
                            format input as the examples.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Button
                    title="Add Metadata"
                    onClick={clickOpenMetadata}
                    size="sm"
                    style={{ background: "#03AA6D" }}
                >
                    <span>Add Metadata &nbsp;</span>
                    {isOpenMetadata ? (
                        <AiOutlineCaretUp size={20} />
                    ) : (
                        <AiOutlineCaretDown size={20} />
                    )}
                </Button>

                <Collapse in={isOpenMetadata}>
                    <div>
                        <Row className="mb-3 mt-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="[Metadata] URL"
                                    style={{ boxShadow: "none" }}
                                    value={url}
                                    onChange={onChangeUrl}
                                    isInvalid={validUrl}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide valid URL or fill empty.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Repo URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="[Metadata] Repo URL"
                                    style={{ boxShadow: "none" }}
                                    value={urlRepo}
                                    onChange={onChangeUrlRepo}
                                    isInvalid={validUrlRepo}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide valid URL or fill empty.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Model Hub URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="[Metadata] Model Hub URL"
                                    style={{ boxShadow: "none" }}
                                    value={urlModel}
                                    onChange={onChangeUrlModel}
                                    isInvalid={validUrlModel}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide valid URL or fill empty.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Dataset Hub URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="[Metadata] Dataset Hub URL"
                                    style={{ boxShadow: "none" }}
                                    value={urlDataset}
                                    onChange={onChangeUrlDataset}
                                    isInvalid={validUrlDataset}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide valid URL or fill empty.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </div>
                </Collapse>
            </Form>

            {/* Spinner for uploading status */}
            {uploadState && (
                <div className="d-flex my-0 mt-3">
                    <div
                        className="spinner-grow text-info"
                        role="status"
                        aria-hidden="true"
                    ></div>
                    <p
                        style={{
                            lineHeight: "35px",
                            margin: 0,
                            marginLeft: "5px",
                        }}
                        className="text-info"
                    >
                        Submitting ...
                    </p>
                </div>
            )}
        </div>
    );
});

export default MetadataFormStep3;
