// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-box {
    margin-top: 5px;
    margin-bottom: 5px;
}

.search-box:hover {
    box-shadow: 1px 1px 8px 1px #dcdcdc;
}

.search-input {
    border: none;
}

.search-icon {
    margin: auto 10px auto 10px;
    display: block;
}

.select-box {
    width: 20%;
}

.small-search-box {
    z-index: 10;
    top: "100%";
    left: 0;
    background: white;
    max-height: 300px;
    overflow-y: scroll;
}

.small-search-box-item {
}

.small-search-box-item:hover {
    background-color: #e6f2ff;
}

.closed-button-search-box {
    top: "0%";
    left: "97%";
}

.title-bold-italic {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
}

.tag-bold {
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/search/SearchBox.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,WAAW;IACX,OAAO;IACP,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;AACA;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".search-box {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.search-box:hover {\n    box-shadow: 1px 1px 8px 1px #dcdcdc;\n}\n\n.search-input {\n    border: none;\n}\n\n.search-icon {\n    margin: auto 10px auto 10px;\n    display: block;\n}\n\n.select-box {\n    width: 20%;\n}\n\n.small-search-box {\n    z-index: 10;\n    top: \"100%\";\n    left: 0;\n    background: white;\n    max-height: 300px;\n    overflow-y: scroll;\n}\n\n.small-search-box-item {\n}\n\n.small-search-box-item:hover {\n    background-color: #e6f2ff;\n}\n\n.closed-button-search-box {\n    top: \"0%\";\n    left: \"97%\";\n}\n\n.title-bold-italic {\n    font-weight: bold;\n    font-style: italic;\n    text-decoration: underline;\n}\n\n.tag-bold {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
