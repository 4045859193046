// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 8px;
}

table thead tr th:last-child {
    max-width: 200px;
    min-width: 100px;
    width: 160px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #94bbe9;
    color: white;
}

.select-size {
    margin-right: 10px;
    /* height:; */
    border: none;
}

.page-count {
    margin: auto 10px auto 0;
}

.page-link {
    background-color: #dddddd;
    color: #333;
    text-decoration: none;
    border-color: #dddddd;
    cursor: pointer;
}

.page-link:hover {
    background-color: #dddddd;
    border-color: #333;
    color: #a0a0a0;
}

.page-active {
    background-color: #757575;
    color: #fff;
}

.page-active:hover {
    background-color: #757575 !important;
    color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/table/LeaderboardTable/LeaderboardTable.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,yBAAyB;IACzB,WAAW;AACf;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B","sourcesContent":["table {\n    font-family: Arial, Helvetica, sans-serif;\n    border-collapse: collapse;\n    width: 100%;\n}\n\ntable td,\ntable th {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\ntable thead tr th:last-child {\n    max-width: 200px;\n    min-width: 100px;\n    width: 160px;\n}\n\ntable tr:nth-child(even) {\n    background-color: #f2f2f2;\n}\n\ntable tr:hover {\n    background-color: #ddd;\n}\n\ntable th {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: center;\n    background-color: #94bbe9;\n    color: white;\n}\n\n.select-size {\n    margin-right: 10px;\n    /* height:; */\n    border: none;\n}\n\n.page-count {\n    margin: auto 10px auto 0;\n}\n\n.page-link {\n    background-color: #dddddd;\n    color: #333;\n    text-decoration: none;\n    border-color: #dddddd;\n    cursor: pointer;\n}\n\n.page-link:hover {\n    background-color: #dddddd;\n    border-color: #333;\n    color: #a0a0a0;\n}\n\n.page-active {\n    background-color: #757575;\n    color: #fff;\n}\n\n.page-active:hover {\n    background-color: #757575 !important;\n    color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
