import { createContext, useEffect, useReducer } from "react";

import { leaderboadReducer } from "../reducers/LeaderboardReducer";
import { LOAD_RESULT_SUCCESS, LOAD_RESULT_FAIL } from "./constants";
import { GET_ALL_SUBMISSION_URL } from "./constants";
import {
    SEMANTIC_TEXTUAL_SIMILARITY_TASK,
    TEXT_CLASSIFICATION_TASK,
    TOKEN_CLASSIFICATION_TASK,
} from "../all_contants";
import axios from "axios";

export const LeaderboardContext = createContext();

const LeaderboardContextProvider = ({ children }) => {
    // state
    const [loaderboardState, dispatch] = useReducer(leaderboadReducer, {
        resultLoading: true,
        results: [],
    });

    const getSubmissionByTask = async (taskFilter) => {
        try {
            const res = await axios.get(GET_ALL_SUBMISSION_URL, {
                params: { task: taskFilter },
            });
            if (res) {
                return res.data;
            }
        } catch (error) {}
        return [];
    };

    const getAllSubmissions = async () => {
        let data = [];
        data.push(
            ...(await getSubmissionByTask(SEMANTIC_TEXTUAL_SIMILARITY_TASK))
        );
        data.push(...(await getSubmissionByTask(TEXT_CLASSIFICATION_TASK)));
        data.push(...(await getSubmissionByTask(TOKEN_CLASSIFICATION_TASK)));
        return data;
    };

    // Function
    const getSubmissions = async () => {
        // Call API by Axios
        const response = await getAllSubmissions();

        if (response)
            dispatch({ type: LOAD_RESULT_SUCCESS, payload: response });
        else dispatch({ type: LOAD_RESULT_FAIL });
    };
    useEffect(() => {
        getSubmissions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Context data
    const loaderboardContextData = { loaderboardState };

    // Return provider
    return (
        <LeaderboardContext.Provider value={loaderboardContextData}>
            {children}
        </LeaderboardContext.Provider>
    );
};

export default LeaderboardContextProvider;
