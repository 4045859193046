import JSONPretty from "react-json-pretty";

const ViewResultStep2 = ({ data }) => {
    return (
        <JSONPretty
            className="px-3 py-2 mt-3"
            style={{
                backgroundColor: "#F7F7F7",
                color: "black",
                maxHeight: "400px",
                overflow: "scroll"
            }}
            data={data}
        />
    );
};

export default ViewResultStep2;
