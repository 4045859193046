export const apiUrl = process.env.REACT_APP_API_URL;

// Dataset URL
export const DATASET_DESCRIPTION_URL = `${apiUrl}${process.env.REACT_APP_DATASET_DESCRIPTION_PATH}`;
export const GET_BENCHMARK_DATASET_URL = `${apiUrl}${process.env.REACT_APP_GET_BENCHMARK_DATASET_PATH}`;
export const ALL_BENCHMARK_NAMES_URL = `${apiUrl}${process.env.REACT_APP_ALL_BENCHMARK_NAMES}`;

// Semantic textual similarity
export const STS_BENCHMARK_URL = `${apiUrl}${process.env.REACT_APP_STS_BENCHMARK_PATH}`;
export const STS_BENCHMARK_DIRECTLY_URL = `${apiUrl}${process.env.REACT_APP_STS_BENCHMARK_DIRECTLY_PATH}`;

// Text classification
export const TEXT_CLS_BENCHMARK_URL = `${apiUrl}${process.env.REACT_APP_TEXT_CLS_BENCHMARK_PATH}`;
export const TEXT_CLS_BENCHMARK_DIRECTLY_URL = `${apiUrl}${process.env.REACT_APP_TEXT_CLS_BENCHMARK_DIRECTLY_PATH}`;

// Token classification
export const TOKEN_CLS_BENCHMARK_URL = `${apiUrl}${process.env.REACT_APP_TOKEN_CLS_BENCHMARK_PATH}`;
export const TOKEN_CLS_BENCHMARK_DIRECTLY_URL = `${apiUrl}${process.env.REACT_APP_TOKEN_CLS_BENCHMARK_DIRECTLY_PATH}`;

// Submission
export const ADD_SUBMISSION_URL = `${apiUrl}${process.env.REACT_APP_ADD_SUBMISSION_PATH}`;
export const GET_ALL_SUBMISSION_URL = `${apiUrl}${process.env.REACT_APP_GET_ALL_SUBMISSION_PATH}`;

