import { createContext, useEffect, useReducer } from "react";
import axios from "axios";

import { submissionReducer } from "../reducers/SubmissionReducer";
import {
    SEMANTIC_TEXTUAL_SIMILARITY_TASK,
    TEXT_CLASSIFICATION_TASK,
    TOKEN_CLASSIFICATION_TASK,
} from "../all_contants";
import {
    LOAD_BENCHMACK_NAME_DATASET_SUCCESS,
    LOAD_BENCHMACK_NAME_DATASET_FAIL,
    ALL_BENCHMARK_NAMES_URL,
} from "./constants";

export const SubmissionContext = createContext();

const SubmissionContextProvider = ({ children }) => {
    // state
    const initState = {};
    initState[SEMANTIC_TEXTUAL_SIMILARITY_TASK] = [];
    initState[TEXT_CLASSIFICATION_TASK] = [];
    initState[TOKEN_CLASSIFICATION_TASK] = [];

    const [dataState, dispatch] = useReducer(submissionReducer, initState);

    // get all benchmark names
    const getAllBenchmarkNames = async (taskFilter) => {
        try {
            const response = await axios.get(ALL_BENCHMARK_NAMES_URL, {
                params: { task: taskFilter },
            });
            if (response) {
                let data = response.data;
                data.sort();
                return data;
            }
        } catch (error) {}
        return [];
    };

    // get all data benchmark of each type
    const getAllBenchmarkNameDataset = async () => {
        try {
            let dataPayload = {};
            // 1. SEMANTIC_TEXTUAL_SIMILARITY_TASK
            dataPayload[SEMANTIC_TEXTUAL_SIMILARITY_TASK] =
                await getAllBenchmarkNames(SEMANTIC_TEXTUAL_SIMILARITY_TASK);

            // 2. TEXT_CLASSIFICATION_TASK
            dataPayload[TEXT_CLASSIFICATION_TASK] = await getAllBenchmarkNames(
                TEXT_CLASSIFICATION_TASK
            );

            // 3. TOKEN_CLASSIFICATION_TASK
            dataPayload[TOKEN_CLASSIFICATION_TASK] = await getAllBenchmarkNames(
                TOKEN_CLASSIFICATION_TASK
            );

            dispatch({
                type: LOAD_BENCHMACK_NAME_DATASET_SUCCESS,
                payload: dataPayload,
            });
        } catch (error) {
            dispatch({
                type: LOAD_BENCHMACK_NAME_DATASET_FAIL,
                payload: initState,
            });
        }
    };
    useEffect(() => {
        getAllBenchmarkNameDataset();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Context data
    const contextData = { dataState };

    // Return provider
    return (
        <SubmissionContext.Provider value={contextData}>
            {children}
        </SubmissionContext.Provider>
    );
};

export default SubmissionContextProvider;
