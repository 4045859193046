import { useContext, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import CardComponent from "../components/data/CardComponent";
import PaginationLayer from "../components/layout/Pagination";
import { reformatCamelText } from "../utils/stringUtils";
import { DatasetContext } from "../contexts/DatasetContext";

const DataPage = () => {
    // Context
    const {
        datasetState: { datasetLoading, dataset, titles },
        searchMetadata,
    } = useContext(DatasetContext);

    // Local State
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [selectedTitle, setSelectedTitle] = useState("all");
    const [cardsPerPage, setCardsPerPages] = useState(5);

    // Local Variable View
    let cardContainerView;
    // In process, data loading
    if (datasetLoading)
        cardContainerView = (
            <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" variant="primary" />
            </div>
        );
    else if (dataset) {
        let cardBody = [];
        let startIdx = (currentPage - 1) * cardsPerPage;
        let endIdx = currentPage * cardsPerPage;
        if (endIdx > dataset.length) endIdx = dataset.length;

        for (let i = startIdx; i < endIdx; i++) {
            cardBody.push(<CardComponent info={dataset[i]} key={i} />);
        }

        cardContainerView = <div className="row">{cardBody}</div>;
    }

    // Event search Cards
    const searchCards = (event) => {
        // pressed key id : 13 -> Enter
        if (event.which === 13)
            searchMetadata(event.target.value, selectedTitle);
        setSearchText(event.target.value.trim());
    };

    // Event filter card by Totals
    const filterCards = (event) => {
        searchMetadata(searchText, event.target.value);
        setSelectedTitle(event.target.value);
        setCurrentPage(1);
    };

    return (
        <div className="my-5">
            <div className="row search-container mb-md-3 mb-sm-2 mb-2">
                {/* Search bar */}
                <div className="col-lg-9 col-md-9 col-sm-12">
                    <input
                        type="text"
                        onKeyUp={searchCards}
                        placeholder="Search meta dataset ..."
                        className="search-bar"
                    />
                </div>

                {/* Filter dropdown */}
                <div className="col-lg-3 col-md-3 col-sm-12 my-md-0 my-sm-2 my-2">
                    <select
                        onChange={filterCards}
                        className="filter-dropdown col-3"
                    >
                        <option value="all" key="all">
                            All
                        </option>
                        {titles.map((title) => (
                            <option value={title} key={title}>
                                {reformatCamelText(title)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {/* View cards container  */}
            {cardContainerView}

            <PaginationLayer
                currentPage={currentPage}
                cardsPerPage={cardsPerPage}
                totalCards={dataset.length}
                setCurrentPage={setCurrentPage}
                setCardsPerPages={setCardsPerPages}
                className="pagination mt-4 d-flex justify-content-center"
            />
        </div>
    );
};

export default DataPage;
