import React, {useState} from 'react'
import "./NavBar.css";


const NavBar = (props) => {

    const {items} = props;
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        const item = items[index];
        setActiveIndex(index);
        props.handleSelectItem(item);
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div>
                <ul className="navbar-nav mr-auto">
                    {
                        items.map((item, index) => {
                                const liClassName = "nav-item" + (activeIndex === index ? " selected" : "");
                                return (
                                    <li
                                        key={index}
                                        className={liClassName}
                                        onClick={() => handleOnClick(index)}
                                    >
                                        <p className="nav-link my-0" style={{cursor:"pointer"}}>{item}</p>
                                    </li>
                                )
                            }
                        )
                    }
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;