import { useContext, useState } from "react";

import StepByStepSubmission from "./StepByStepSubmission";
import { NONE_TASK } from "../../all_contants";
import { SubmissionContext } from "../../contexts/SubmissionContext";
import { reformatCamelText } from "../../utils/stringUtils";

const SubmissionPage = () => {
    // Context
    const { dataState } = useContext(SubmissionContext);

    // Local State
    const [selectedTask, setSelectedTask] = useState(NONE_TASK);
    const [selectedSupportedBenchmark, setSelectedSupportedBenchmark] =
        useState(NONE_TASK);
    const [disableCombobox, setDisableCombobox] = useState(false);
    const [dataFilename, setDataFilename] = useState("");
    const [fileBlob, setFileBlob] = useState(null);

    // Event select
    const selectTaskOption = (event) => {
        setSelectedTask(event.target.value);
    };
    const selectSupportBenchmarkOption = (event) => {
        setSelectedSupportedBenchmark(event.target.value);
    };

    return (
        <div className="mt-5">
            <p>
                You can submit your model here without having to register an
                account.
            </p>

            <div className="row d-flex justify-content-between">
                <div className="col-lg-6 col-md-12">
                    <div
                        style={{ fontSize: "24px" }}
                        className="py-2 mb-4 border-gradient border-gradient-green border-only-bottom"
                    >
                        Submit Configs
                    </div>

                    {/* Select Task Evaluation */}
                    <div className="row d-flex align-items-center mb-2">
                        <p className="m-0 col-lg-3 col-md-3 col-sm-12 h-100">
                            Evaluation Task
                        </p>
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <select
                                onChange={selectTaskOption}
                                className="filter-dropdown w-100"
                                value={selectedTask}
                                disabled={disableCombobox}
                            >
                                <option value={NONE_TASK} key={NONE_TASK}>
                                    None
                                </option>
                                {Object.keys(dataState).map((key) => (
                                    <option value={key} key={key}>
                                        {reformatCamelText(key)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {/* Select Supported Benchmark */}
                    <div className="row d-flex align-items-center mb-2">
                        <p className="m-0 col-lg-3 col-md-3 col-sm-12 h-100">
                            Supported Benchmark
                        </p>
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <select
                                onChange={selectSupportBenchmarkOption}
                                className="filter-dropdown w-100"
                                disabled={
                                    selectedTask === NONE_TASK ||
                                    disableCombobox
                                }
                                value={selectedSupportedBenchmark}
                            >
                                <option value={NONE_TASK} key={NONE_TASK}>
                                    None
                                </option>
                                {dataState[selectedTask]
                                    ? dataState[selectedTask]
                                          .sort()
                                          .map((item) => (
                                              <option value={item} key={item}>
                                                  {item}
                                              </option>
                                          ))
                                    : ""}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 mt-lg-0 mt-md-3 mt-sm-3">
                    <StepByStepSubmission
                        selectedTask={selectedTask}
                        selectedBenchmark={selectedSupportedBenchmark}
                        fileBlob={fileBlob}
                        setFileBlob={setFileBlob}
                        dataFilename={dataFilename}
                        setDataFilename={setDataFilename}
                        setDisableCombobox={setDisableCombobox}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubmissionPage;
