import {
    LOAD_DATASET_SUCCESS,
    LOAD_DATASET_FAIL,
    FILTER_DATASET_BY_META,
} from "../contexts/constants";

export const datasetReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOAD_DATASET_SUCCESS:
            const titles = payload.map((item) =>
                item.title ? item.title : item.task
            );
            return {
                ...state,
                datasetLoading: false,
                dataset: [...payload],
                titles: [...new Set(titles)],
            };

        case LOAD_DATASET_FAIL:
            return {
                ...state,
                datasetLoading: false,
                dataset: [],
            };

        case FILTER_DATASET_BY_META:
            return {
                ...state,
                dataset: payload,
            };

        default:
            return state;
    }
};
