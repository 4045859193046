import { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactJson from "react-json-view";

import SearchBox from "../../components/search/SearchBox";
import NavBar from "../../components/navbar/NavBar";
import { LeaderboardTable } from "../../components/table/LeaderboardTable/LeaderboardTable";
import { SEMANTIC_SIMILARITY_COLUMNS } from "./columns/SemanticSimilarityColumns";
import {
    getTableColumns,
    processDataset,
    queryTaskDataset,
} from "../../utils/leaderboardUtils";

const LeaderboardPage = () => {
    const taskMapping = {
        "Semantic Similarity": "semantic_textual_similarity",
        "Text Classification": "text_classification",
        "Token Classification": "token_classification",
    };
    const taskItems = [
        "Semantic Similarity",
        "Text Classification",
        "Token Classification",
    ];

    const [tableColumns, setTableColumns] = useState(
        SEMANTIC_SIMILARITY_COLUMNS
    );
    const [recommendData, setRecommendData] = useState([]);
    const [tableDataset, setTableDataset] = useState([]);
    const [filterTableDataset, setFilterTableDataset] = useState([]);

    const [jsonObjectMoreDetails, setJsonObjectMoreDetails] = useState([]);
    const [showMoreDetails, setShowMoreDetails] = useState(false);
    const handleClose = () => setShowMoreDetails(false);
    const handleShow = () => setShowMoreDetails(true);
    const eventMoreDataDetails = (data) => {
        setJsonObjectMoreDetails(data);
        handleShow();
    };

    const sortDataByTimestamp = (dataset) => {
        let copyData = [...dataset];
        copyData.sort((a, b) => {
            const dateTime1 = a.Timestamp.split(" ");
            const date1 = dateTime1[0].split("/").map((item) => parseInt(item));
            const time1 = dateTime1[1].split(":").map((item) => parseInt(item));
            const timeObj1 = new Date(
                date1[2],
                date1[1] - 1,
                date1[0],
                time1[0],
                time1[1],
                time1[2]
            );

            const dateTime2 = b.Timestamp.split(" ");
            const date2 = dateTime2[0].split("/").map((item) => parseInt(item));
            const time2 = dateTime2[1].split(":").map((item) => parseInt(item));
            const timeObj2 = new Date(
                date2[2],
                date2[1] - 1,
                date2[0],
                time2[0],
                time2[1],
                time2[2]
            );

            return timeObj2 - timeObj1;
        });
        return copyData;
    };

    useEffect(() => {
        queryTaskDataset("semantic_textual_similarity").then((res) => {
            let processedData = processDataset(
                res.data,
                "semantic_textual_similarity"
            );
            for (let item of processedData) {
                item.displayTag = "";
                if (item.Tags) {
                    item.displayTag = item.Tags.map((i) => "#" + i).join(", ");
                }
            }

            // sort data by timestamp
            const timestampSortData = sortDataByTimestamp(processedData);

            // config recommend search data
            const _data = [];
            // 1. Author
            _data.push(
                ...timestampSortData
                    .map((item) => item.Author)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Author",
                        title: `Author: ${item}`,
                    }))
            );
            // 2. ModelName
            _data.push(
                ...timestampSortData
                    .map((item) => item.ModelName)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "ModelName",
                        title: `Model: ${item}`,
                    }))
            );
            // 3. Benchmark
            _data.push(
                ...timestampSortData
                    .map((item) => item.Benchmark)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Benchmark",
                        title: `Benchmark: ${item}`,
                    }))
            );
            // 4. Tags
            _data.push(
                ...timestampSortData
                    .map((item) => item.Tags)
                    .flat()
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Tags",
                        title: `# ${item.toUpperCase()}`,
                    }))
            );
            setRecommendData(_data);
            setTableDataset(timestampSortData);
            setFilterTableDataset(timestampSortData);
        });
    }, []);

    const handleOnEnterSearchBox = (tags, authors, models, benchmarks) => {
        let res = [...tableDataset];
        // 1. search tags
        if (tags.length > 0)
            for (let tag of tags)
                res = res.filter((data) => data.Tags.includes(tag));
        // 2. benchmark search
        if (benchmarks.length > 0)
            res = res.filter((data) => benchmarks.includes(data.Benchmark));
        // 3. model
        if (models.length > 0)
            res = res.filter((data) => models.includes(data.ModelName));
        // 4. author
        if (authors.length > 0)
            res = res.filter((data) => authors.includes(data.Author));
        setFilterTableDataset(res);
    };

    const handleSelectItem = (item) => {
        const task = taskMapping[item];
        setTableColumns(getTableColumns(task));
        queryTaskDataset(task).then((res) => {
            const processedData = processDataset(res.data, task);
            for (let item of processedData) {
                item.displayTag = "";
                if (item.Tags) {
                    item.displayTag = item.Tags.map((i) => "#" + i).join(", ");
                }
            }

            // sort data by timestamp
            const timestampSortData = sortDataByTimestamp(processedData);

            // config recommend search data
            const _data = [];
            // 1. Tags
            _data.push(
                ...timestampSortData
                    .map((item) => item.Tags)
                    .flat()
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Tags",
                        title: `# ${item.toUpperCase()}`,
                    }))
            );
            // 2. Author
            _data.push(
                ...timestampSortData
                    .map((item) => item.Author)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Author",
                        title: `Author: ${item}`,
                    }))
            );
            // 3. Model
            _data.push(
                ...timestampSortData
                    .map((item) => item.ModelName)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "ModelName",
                        title: `Model: ${item}`,
                    }))
            );
            // 4. Benchmark
            _data.push(
                ...timestampSortData
                    .map((item) => item.Benchmark)
                    .filter((item, i, ar) => ar.indexOf(item) === i)
                    .sort()
                    .map((item) => ({
                        text: item,
                        type: "Benchmark",
                        title: `Benchmark: ${item}`,
                    }))
            );
            setRecommendData(_data);
            setTableDataset(timestampSortData);
            setFilterTableDataset(timestampSortData);
        });
    };

    return (
        <div className="my-5">
            <div
                style={{ fontSize: "28px" }}
                className="py-2 mb-4 border-gradient border-gradient-green border-only-bottom"
            >
                🏆 &nbsp; VA Models Leaderboard
            </div>
            <NavBar items={taskItems} handleSelectItem={handleSelectItem} />

            <SearchBox
                handleOnEnterSearchBox={handleOnEnterSearchBox}
                recommendSearchData={recommendData}
            />

            <LeaderboardTable
                tableColumns={tableColumns}
                tableDataset={filterTableDataset}
                showMoreDetails={eventMoreDataDetails}
            />

            {/* Modal to show more detailed information */}
            <div>
                <Modal
                    show={showMoreDetails}
                    onHide={handleClose}
                    animation={true}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Detailed Information
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <ReactJson
                                src={jsonObjectMoreDetails}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={handleClose}
                            style={{ background: "rgb(3, 170, 109)" }}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};

export default LeaderboardPage;
