import { sortScore } from "../../../utils/sortUtils";

export const TOKEN_CLASSIFICATION_COLUMNS = [
    // {
    //     Header: "Id",
    //     accessor: "id",
    // },
    {
        Header: "Author",
        accessor: "Author",
    },
    {
        Header: "Model",
        accessor: "ModelName",
    },
    {
        Header: "Benchmark",
        accessor: "Benchmark",
    },
    {
        Header: "Accuracy",
        accessor: "accuracy",
        sortType: sortScore,
    },
    {
        Header: "Precision",
        accessor: "precision",
        sortType: sortScore,
    },
    {
        Header: "Recall",
        accessor: "recall",
        sortType: sortScore,
    },
    {
        Header: "F1 Score",
        accessor: "f1",
        sortType: sortScore,
    },
    {
        Header: "Entries",
        accessor: "Entries",
        sortType: sortScore,
    },
    {
        Header: "Timestamp",
        accessor: "Timestamp",
    },
    {
        Header: "Tags",
        accessor: "displayTag",
    },
];
