export const readJsonFile = (file) =>
    new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target) {
                try {
                    let data = JSON.parse(event.target.result);
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            }
        };

        fileReader.onerror = (error) => reject(error);
        fileReader.readAsText(file);
    });

export const saveJsonFile = (filePath, json_data) => {
    const data = new Blob([JSON.stringify(json_data, null, 2)], {
        type: "text/json",
    });
    const jsonURL = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = jsonURL;
    link.setAttribute("download", filePath);
    link.click();
    document.body.removeChild(link);
};
