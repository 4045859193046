const OverviewPage = () => {
    return (
        <div className="my-5">
            <p>
                In general, LMBs is a platform that allows developers to build,
                track and submit their models to the leaderboard.
            </p>
            <h1>What is LMBs?</h1>
        </div>
    );
};

export default OverviewPage;
