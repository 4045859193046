const HeaderLayer = () => {
    return (
        <>
            <h1 className="header_top_gradient m-0 py-4">
                Language Model Benchmark Board
            </h1>
            <h4 className="header_top_gradient m-0 py-3">
                Easy, Efficient, and Sharable
            </h4>
        </>
    );
};

export default HeaderLayer;
