const Step = (props) => {
    return (
        <div className={"stepBlock" + (props.selected ? " step-selected" : "")}>
            <div className="circleWrapper">
                <div className="step-circle">{props.index + 1}</div>
            </div>
            <span>{props.label}</span>
        </div>
    );
};

const StepNavigation = (props) => {
    return (
        <div className="step-boby mt-4">
            <div className="stepWrapper">
                {props.labelArray.map((item, index) => (
                    <Step
                        key={index}
                        index={index}
                        label={item}
                        updateStep={props.updateStep}
                        selected={props.currentStep === index + 1}
                    ></Step>
                ))}
            </div>
        </div>
    );
};

export default StepNavigation;
