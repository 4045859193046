import { LOAD_RESULT_SUCCESS, LOAD_RESULT_FAIL } from "../contexts/constants";

export const leaderboadReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOAD_RESULT_SUCCESS:
            return {
                ...state,
                resultLoading: false,
                results: [...payload],
            };

        case LOAD_RESULT_FAIL:
            return {
                ...state,
                resultLoading: false,
                results: [],
            };

        default:
            return state;
    }
};
