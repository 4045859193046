import { sortScore } from "../../../utils/sortUtils";

export const SEMANTIC_SIMILARITY_COLUMNS = [
    // {
    //     Header: "Id",
    //     accessor: "id",
    // },
    {
        Header: "Author",
        accessor: "Author",
    },
    {
        Header: "Model",
        accessor: "ModelName",
    },
    {
        Header: "Benchmark",
        accessor: "Benchmark",
    },
    {
        Header: "Recall@1",
        accessor: "recall@1",
        sortType: sortScore,
    },
    {
        Header: "MRR@1",
        accessor: "mrr@1",
        sortType: sortScore,
    },
    {
        Header: "MAP@1",
        accessor: "map@1",
        sortType: sortScore,
    },
    {
        Header: "Recall@5",
        accessor: "recall@5",
        sortType: sortScore,
    },
    {
        Header: "MRR@5",
        accessor: "mrr@5",
        sortType: sortScore,
    },
    {
        Header: "MAP@5",
        accessor: "map@5",
        sortType: sortScore,
    },
    {
        Header: "Entries",
        accessor: "Entries",
        sortType: sortScore,
    },
    {
        Header: "Timestamp",
        accessor: "Timestamp",
    },
    {
        Header: "Tags",
        accessor: "displayTag",
    },
];
