export const apiUrl =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_API_URL
        : "http://localhost:5000";

// ================== FOR URL DEFINATION =====================
// Dataset URL
export const DATA_DESCRIPTION_URL = `${apiUrl}${process.env.REACT_APP_DATASET_DESCRIPTION_PATH}`;
export const DATA_DOWNLOAD_URL = `${apiUrl}${process.env.REACT_APP_GET_BENCHMARK_DATASET_PATH}`;
export const ALL_BENCHMARK_NAMES_URL = `${apiUrl}${process.env.REACT_APP_ALL_BENCHMARK_NAMES}`;

// Semantic textual similarity
export const STS_BENCHMARK_POST_URL = `${apiUrl}${process.env.REACT_APP_STS_BENCHMARK_PATH}`;

// Text classification
export const TEXT_CLS_BENCHMARK_POST_URL = `${apiUrl}${process.env.REACT_APP_TEXT_CLS_BENCHMARK_PATH}`;

// Token classification
export const TOKEN_CLS_BENCHMARK_POST_URL = `${apiUrl}${process.env.REACT_APP_TOKEN_CLS_BENCHMARK_PATH}`;

// Submission
export const ADD_SUBMISSION_URL = `${apiUrl}${process.env.REACT_APP_ADD_SUBMISSION_PATH}`;
export const GET_ALL_SUBMISSION_URL = `${apiUrl}${process.env.REACT_APP_GET_ALL_SUBMISSION_PATH}`;

// ===================================================

// ================== FOR REDUX ACTION =====================
// For Dataset Redux
export const LOAD_DATASET_SUCCESS = "LOAD_DATASET_SUCCESS";
export const LOAD_DATASET_FAIL = "LOAD_DATASET_FAIL";
export const FILTER_DATASET_BY_META = "FILTER_DATASET_BY_META";

// For Submission Redux
export const LOAD_BENCHMACK_NAME_DATASET_SUCCESS =
    "LOAD_BENCHMACK_NAME_DATASET_SUCCESS";
export const LOAD_BENCHMACK_NAME_DATASET_FAIL =
    "LOAD_BENCHMACK_NAME_DATASET_FAIL";

// For Leaderboard Redux
export const LOAD_RESULT_SUCCESS = "LOAD_RESULT_SUCCESS";
export const LOAD_RESULT_FAIL = "LOAD_RESULT_FAIL";

// ===================================================
