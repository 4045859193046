import EvaluationService from "../services/EvaluationService";
import { SEMANTIC_SIMILARITY_COLUMNS } from "../views/LeaderboardPage/columns/SemanticSimilarityColumns";
import { TEXT_CLASSIFICATION_COLUMNS } from "../views/LeaderboardPage/columns/TextClassificationColumns";
import { TOKEN_CLASSIFICATION_COLUMNS } from "../views/LeaderboardPage/columns/TokenClassificationColumns";

export const getTableColumns = (task) => {
    switch (task) {
        case "semantic_textual_similarity":
            return SEMANTIC_SIMILARITY_COLUMNS;
        case "text_classification":
            return TEXT_CLASSIFICATION_COLUMNS;
        case "token_classification":
            return TOKEN_CLASSIFICATION_COLUMNS;
        default:
            throw new Error("Task is not defined");
    }
};

export const queryTaskDataset = (task) => {
    let data = null;
    switch (task) {
        case "semantic_textual_similarity":
            data = EvaluationService.getAllSubmissions(
                "semantic_textual_similarity"
            );
            break;
        case "text_classification":
            data = EvaluationService.getAllSubmissions("text_classification");
            break;
        case "token_classification":
            data = EvaluationService.getAllSubmissions("token_classification");
            break;
        default:
            throw new Error("Task is not defined");
    }
    return data;
};

export const processDataset = (dataset, task) => {
    switch (task) {
        case "semantic_textual_similarity":
            return processSemanticSimilarityDataset(dataset);
        case "text_classification":
            return processTextClassificationDataset(dataset);
        case "token_classification":
            return processTokenClassificationDataset(dataset);
        default:
            throw new Error("Task is not defined");
    }
};

const processSemanticSimilarityDataset = (dataset) => {
    const ans = [];
    dataset.map((data) => {
        const scores = data["scores"];
        let flattenScores = {};
        scores.map((score) => {
            flattenScores = { ...flattenScores, ...score };
            return null;
        });
        ans.push({ ...data, ...flattenScores });
        return null;
    });
    return ans;
};

const processTextClassificationDataset = (dataset) => {
    const ans = [];
    dataset.map((data) => {
        const scores = data["scores"];
        let flattenScores = {};
        scores.map((score) => {
            flattenScores = { ...flattenScores, ...score };
            return null;
        });
        ans.push({ ...data, ...flattenScores });
        return null;
    });
    return ans;
};

const processTokenClassificationDataset = (dataset) => {
    const ans = [];
    dataset.map((data) => {
        const scores = data["scores"];
        let flattenScores = {};
        scores.map((score) => {
            flattenScores = { ...flattenScores, ...score };
            return null;
        });
        ans.push({ ...data, ...flattenScores });
        return null;
    });
    return ans;
};
