import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { useTable, useSortBy, usePagination } from "react-table";
import { getPaginationRange } from "../../../utils/paginationUtils";
import "./LeaderboardTable.css";

export const LeaderboardTable = ({
    tableColumns,
    tableDataset,
    showMoreDetails,
}) => {
    const tagDict = tableDataset
        .map((item) => item.Tags)
        .flat()
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .reduce((acc, item, index) => {
            acc[item] = "primary";
            return acc;
        }, {});

    const checkTags = (tagText) => {
        if (!tagText) return false;
        const tags = tagText.split(",").map((item) => item.trim());
        if (tags.length === 0) return false;
        const filterTags = tags.filter((item) => item.startsWith("#"));
        return tags.length === filterTags.length;
    };

    const genBadgeTags = (tagText) => {
        const tags = tagText
            .split(",")
            .map((item) => item.trim().substring(1))
            .sort((a, b) => a.length - b.length);

        const uiTags = tags.map((item) => (
            <Chip
                label={item.toUpperCase()}
                size="small"
                // avatar={
                //     <Avatar sx={{ width: 12, widows: 12 }}>
                //         <b>#</b>
                //     </Avatar>
                // }
                color={tagDict[item]}
                variant="outlined"
                style={{ fontSize: 9, fontWeight: "bold" }}
            />
        ));
        return (
            <Stack
                direction="row"
                useFlexGap
                flexWrap="wrap"
                justifyContent="start"
                alignItems="center"
                spacing={0.3}
                style={{ zIndex: 1, width: "160px" }}
            >
                {uiTags}
            </Stack>
        );
    };

    const columns = tableColumns;
    const data = tableDataset;
    const tableInstance = useTable({ columns, data }, useSortBy, usePagination);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        gotoPage,
        pageCount,
        setPageSize,
        pageOptions,
        state,
    } = tableInstance;

    const { pageIndex, pageSize } = state;
    const array = getPaginationRange(pageCount, pageIndex + 1, 1);

    const showAllMetadata = (item) => {
        showMoreDetails(item.Records[0]);
    };

    return (
        <div className="row" style={{ marginBottom: "-20px" }}>
            <div
                className="col-12 overflow-auto"
                style={{ maxHeight: "650px", overflowY: "scroll" }}
            >
                <table
                    {...getTableProps()}
                    className="customize-table"
                    style={{ fontSize: 14 }}
                >
                    <thead
                        style={{
                            position: "sticky",
                            top: -1,
                            left: 0,
                            zIndex: 2,
                        }}
                    >
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(
                                            column.getSortByToggleProps()
                                        )}
                                    >
                                        {column.render("Header")}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " ⬇"
                                                    : " ⬆"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="black-90-color">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onDoubleClick={(event) => {
                                        event.preventDefault();
                                        showAllMetadata(data[parseInt(row.id)]);
                                    }}
                                    title="Double click to show detailed information"
                                >
                                    {row.cells.map((cell) => {
                                        return typeof cell.value ===
                                            "number" ? (
                                            <td
                                                {...cell.getCellProps()}
                                                className="text-center"
                                                title={`Real value: ${cell.value}`}
                                            >
                                                {Number.isInteger(cell.value)
                                                    ? cell.value
                                                    : String(cell.value)
                                                          .length <= 4
                                                    ? cell.value
                                                    : cell.value.toFixed(3)}
                                            </td>
                                        ) : (
                                            <td
                                                {...cell.getCellProps()}
                                                className="text-center"
                                            >
                                                {checkTags(cell.value)
                                                    ? genBadgeTags(cell.value)
                                                    : cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="col d-flex mt-3 justify-content-center">
                <span className="page-count">
                    Page <b>{pageIndex + 1}</b> of <b>{pageOptions.length}</b>
                </span>
                <select
                    className="select-size"
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {[10, 25, 50].map((pageSize) => (
                        <option
                            key={pageSize}
                            value={pageSize}
                            className="dropdown-item"
                        >
                            {pageSize} / page
                        </option>
                    ))}
                </select>

                <ul className="pagination pagination-md" style={{ margin: 0 }}>
                    <li className="page-item" onClick={() => gotoPage(0)}>
                        <span className="page-link">&laquo;</span>
                    </li>
                    <li className="page-item" onClick={() => previousPage()}>
                        <span className="page-link">&lsaquo;</span>
                    </li>
                    {array.map((value) => {
                        if (
                            typeof value === "string" &&
                            value.includes("...")
                        ) {
                            return (
                                <li className="page-item" key={value}>
                                    <span className="page-link">{value}</span>
                                </li>
                            );
                        } else {
                            return (
                                <li
                                    className="page-item"
                                    key={value}
                                    onClick={() => gotoPage(value - 1)}
                                >
                                    <span
                                        className={
                                            "page-link" +
                                            (pageIndex + 1 === value
                                                ? " page-active"
                                                : "")
                                        }
                                    >
                                        {value}
                                    </span>
                                </li>
                            );
                        }
                    })}
                    <li className="page-item" onClick={() => nextPage()}>
                        <span className="page-link">&rsaquo;</span>
                    </li>
                    <li
                        className="page-item"
                        onClick={() => gotoPage(pageCount - 1)}
                    >
                        <span className="page-link">&raquo;</span>
                    </li>
                </ul>
            </div>
        </div>
    );
};
