import { createContext, useEffect, useReducer } from "react";
import axios from "axios";

import { datasetReducer } from "../reducers/DatasetReducer";
import {
    LOAD_DATASET_FAIL,
    LOAD_DATASET_SUCCESS,
    FILTER_DATASET_BY_META,
    DATA_DESCRIPTION_URL,
} from "./constants";
import {
    SEMANTIC_TEXTUAL_SIMILARITY_TASK,
    TEXT_CLASSIFICATION_TASK,
    TOKEN_CLASSIFICATION_TASK,
} from "../all_contants";

export const DatasetContext = createContext();

const DatasetContextProvider = ({ children }) => {
    // state
    const [datasetState, dispatch] = useReducer(datasetReducer, {
        datasetLoading: true,
        dataset: [],
        titles: [],
    });

    // Function
    const apiGetDatasetsByTask = async (taskFilter) => {
        try {
            const response = await axios.get(DATA_DESCRIPTION_URL, {
                params: { task: taskFilter },
            });
            if (response) {
                let data = response.data;
                data.sort((item1, item2) =>
                    item1.name >= item2.name ? 1 : -1
                );
                return data;
            }
        } catch (error) {}
        return [];
    };

    const apiGetAllDatasets = async () => {
        const data = [];
        // SEMANTIC_TEXTUAL_SIMILARITY_TASK
        data.push(
            ...(await apiGetDatasetsByTask(SEMANTIC_TEXTUAL_SIMILARITY_TASK))
        );
        // TEXT_CLASSIFICATION_TASK
        data.push(...(await apiGetDatasetsByTask(TEXT_CLASSIFICATION_TASK)));
        // TOKEN_CLASSIFICATION_TASK
        data.push(...(await apiGetDatasetsByTask(TOKEN_CLASSIFICATION_TASK)));

        // Sort by Task
        data.sort((item1, item2) => (item1.task >= item2.task ? 1 : -1));
        return data;
    };

    // Find by Name, Task, Description
    const getAllDatasets = async () => {
        try {
            // Call API by Axios
            const data = await apiGetAllDatasets();
            if (data) {
                dispatch({
                    type: LOAD_DATASET_SUCCESS,
                    payload: data,
                });
            } else dispatch({ type: LOAD_DATASET_FAIL });
        } catch (error) {
            dispatch({ type: LOAD_DATASET_FAIL });
        }
    };
    useEffect(() => {
        getAllDatasets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Search by metadata
    const searchMetadata = async (metadata, taskFilter) => {
        let allData = [];
        if (taskFilter !== "all")
            allData = await apiGetDatasetsByTask(taskFilter);
        else allData = await apiGetAllDatasets();

        if (!metadata) {
            dispatch({
                type: FILTER_DATASET_BY_META,
                payload: allData,
            });
            return;
        }

        let results = [];
        for (let i = 0; i < allData.length; i++) {
            const data = { ...allData[i] };
            for (let key in data) {
                if (
                    String(data[key])
                        .trim()
                        .toLowerCase()
                        .includes(metadata.trim().toLowerCase())
                ) {
                    results.push(data);
                }
            }
        }

        dispatch({
            type: FILTER_DATASET_BY_META,
            payload: results,
        });
    };

    // Context data
    const datasetContextData = {
        datasetState,
        searchMetadata,
    };

    // Return provider
    return (
        <DatasetContext.Provider value={datasetContextData}>
            {children}
        </DatasetContext.Provider>
    );
};

export default DatasetContextProvider;
