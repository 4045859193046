import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";

const DataLoadStep1 = ({
    setFileBlob,
    setDataFilename,
    uploadState,
    setFullnamePath,
}) => {
    // Event for File Selection
    const selectDataFileEvent = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const filename = event.target.files[0].name;
            setFileBlob(event.target.files[0]);
            setDataFilename(filename);
            setFullnamePath(event.target.value);

            if (!filename.endsWith(".json")) {
                toast.error("Please choice .JSON files for Model submittion!");
            }
        } else {
            setDataFilename("");
            setFileBlob(null);
        }
    };

    return (
        <div className="mb-0">
            <Form.Group>
                <Form.Label className="small">
                    Upload your predicted data here
                </Form.Label>
                <Form.Control
                    style={{ boxShadow: "none" }}
                    type="file"
                    onChange={selectDataFileEvent}
                    accept=".jsonl,.json"
                />
                <Form.Label className="small mt-2">
                    <i>
                        (Note: Only <b>JSON</b> file)
                    </i>
                </Form.Label>
            </Form.Group>

            {/* Spinner for uploading status */}
            {uploadState && (
                <div className="d-flex my-0">
                    <div
                        className="spinner-grow text-info"
                        role="status"
                        aria-hidden="true"
                    ></div>
                    <p
                        style={{
                            lineHeight: "35px",
                            margin: 0,
                            marginLeft: "5px",
                        }}
                        className="text-info"
                    >
                        Uploading ...
                    </p>
                </div>
            )}
        </div>
    );
};

export default DataLoadStep1;
