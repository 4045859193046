import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import axios from "axios";

import { DATA_DOWNLOAD_URL } from "../../contexts/constants";
import { saveJsonFile } from "../../utils/fileUtils";

const CardDataModal = ({ info }) => {
    // Local State
    const [show, setShow] = useState(true);
    const [downloadState, setDownloadState] = useState(false);
    const handleClose = () => setShow(false);

    // Event download data
    const handleDownload = () => {
        toast.dismiss()
        setDownloadState(true);

        // Call API
        axios
            .get(DATA_DOWNLOAD_URL, {
                params: {
                    task: info.task ? info.task : "",
                    benchmark_name: info.name ? info.name : "",
                },
            })
            .then((res) => {
                setDownloadState(false);
                saveJsonFile(
                    `${info.name ? info.name : "dataset_info"}.json`,
                    res.data
                );

                toast.success(
                    `Download ${
                        info.name ? info.name : ""
                    } dataset SUCCESSFULLY!`
                );
            })
            .catch((error) => {
                setDownloadState(false);
                toast.error(
                    `Download ${info.name ? info.name : ""} dataset FAIL!`
                );
            });
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            animation={true}
            centered
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Dataset Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="customized_table">
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Description</th>
                        </tr>
                    </thead>

                    <tbody>
                        {Object.keys(info).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{info[key]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                {downloadState && (
                    <Spinner
                        animation="border"
                        variant="info"
                        className="mx-3 "
                    />
                )}
                <Button variant="primary" onClick={handleDownload} style={{background: "#03AA6D"}}>
                    Download
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CardDataModal;
