function compareBasic(a, b) {
    return a === b ? 0 : a > b ? 1 : -1
}

function getRowValuesByColumnID(row1, row2, columnId) {
    return [row1.values[columnId], row2.values[columnId]]
}

export const sortScore = (rowA, rowB, columnId) => {
    let [a, b] = getRowValuesByColumnID(rowA, rowB, columnId);
    if (a === undefined) a = 0;
    if (b === undefined) b = 0;
    return compareBasic(a, b);
}