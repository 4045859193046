import {
    LOAD_BENCHMACK_NAME_DATASET_SUCCESS,
    LOAD_BENCHMACK_NAME_DATASET_FAIL,
} from "../contexts/constants";

export const submissionReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOAD_BENCHMACK_NAME_DATASET_SUCCESS:
        case LOAD_BENCHMACK_NAME_DATASET_FAIL:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
