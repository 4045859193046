import { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";

import StepNavigation from "./component/FlowStepHeader";
import DataLoadStep1 from "./component/DataLoadStep1";
import ViewResultStep2 from "./component/ViewResultStep2";
import MetadataFormStep3 from "./component/MetadataFormStep3";
import { NONE_TASK } from "../../all_contants";
import {
    SEMANTIC_TEXTUAL_SIMILARITY_TASK,
    TEXT_CLASSIFICATION_TASK,
    TOKEN_CLASSIFICATION_TASK,
    LOCAL_STORAGE_TASK,
    LOCAL_STORAGE_BENCHMARK,
    LOCAL_STORAGE_FULLNAME_PATH,
} from "../../all_contants";
import {
    STS_BENCHMARK_POST_URL,
    TEXT_CLS_BENCHMARK_POST_URL,
    TOKEN_CLS_BENCHMARK_POST_URL,
    ADD_SUBMISSION_URL,
} from "../../contexts/constants";
import setAuthToken from "../../utils/setAuthToken";
import {
    saveLocalStorage,
    getLocalStorage,
    removeItemLocalStorage,
} from "../../utils/storeage";

const StepByStepSubmission = ({
    selectedTask,
    selectedBenchmark,
    fileBlob,
    setFileBlob,
    dataFilename,
    setDataFilename,
    setDisableCombobox,
}) => {
    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            removeItemLocalStorage(LOCAL_STORAGE_TASK);
            removeItemLocalStorage(LOCAL_STORAGE_BENCHMARK);
            removeItemLocalStorage(LOCAL_STORAGE_FULLNAME_PATH);
        };
    }, []);

    // ref
    const ref = useRef();

    // state
    const [currentIndex, setCurrentIndex] = useState(0);
    const [uploadState, setUploadState] = useState(false);
    const [fullnamePath, setFullnamePath] = useState("");
    const [jsonData, setJsonData] = useState(null);

    const labelArray = ["Data Upload", "Evaluation Result", "Add Metadata"];

    // Init localstorage
    if (getLocalStorage(LOCAL_STORAGE_TASK) === null) {
        saveLocalStorage(LOCAL_STORAGE_TASK, selectedTask);
    }
    if (getLocalStorage(LOCAL_STORAGE_BENCHMARK) === null) {
        saveLocalStorage(LOCAL_STORAGE_BENCHMARK, selectedBenchmark);
    }
    if (getLocalStorage(LOCAL_STORAGE_FULLNAME_PATH) === null) {
        saveLocalStorage(LOCAL_STORAGE_FULLNAME_PATH, fullnamePath);
    }

    const nextButtonClick = (event) => {
        toast.dismiss();

        // Submit event
        if (event.target.value === "submit") {
            let checkerInputValid = ref.current.checkValidInput();
            if (!checkerInputValid) {
                toast.error("Please checking valid input!");
                return;
            }

            const metadata = ref.current.getMetadata();

            if (jsonData) {
                let item = { ...jsonData };
                item["Author"] = metadata.author;
                item["ModelName"] = metadata.model;
                item["Tags"] = metadata.tags.map((item) => item.substring(1));
                item["Task"] = selectedTask;
                if (item.benchmark_name) delete item.benchmark_name;
                item["Benchmark"] = selectedBenchmark;
                item["Metadata"] = {
                    url: metadata.url,
                    repo_url: metadata.urlRepo,
                    model_hub_url: metadata.urlModel,
                    dataset_hub_url: metadata.urlDataset,
                };
                if (!Array.isArray(item.scores)) {
                    item["scores"] = [item.scores]
                }

                setJsonData(item);
                ref.current.updateTrueUploadStatus();
                setAuthToken(process.env.REACT_APP_TOKEN_AUTHENTICATION);
                axios
                    .post(ADD_SUBMISSION_URL, item)
                    .then((response) => {
                        // Reset Authentication
                        setAuthToken(null);

                        if (response.status === 200) {
                            toast.success(`Message: ${response.data.message}`);
                        } else {
                            toast.error(
                                `Server Error! Upload result file ${dataFilename} FAIL!`
                            );
                        }

                        // Reset UI
                        setCurrentIndex(0);
                        setDisableCombobox(false);
                        ref.current.resetAll();
                    })
                    .catch((error) => {
                        // Reset Authentication
                        setAuthToken(null);

                        toast.error(`Upload result file ${dataFilename} FAIL!`);
                        toast.error(`Server error - 403 Forbidden`);

                        // ResetUI
                        ref.current.updateFalseUploadStatus();
                    });
            }
        }

        // Check validation Data Uploading Phase
        if (currentIndex === 0) {
            let errorChecking = false;
            if (selectedTask === NONE_TASK) {
                toast.error("Please select TASK option before submittion!");
                errorChecking = true;
            } else if (
                selectedTask !== NONE_TASK &&
                selectedBenchmark === NONE_TASK
            ) {
                toast.error(
                    "Please select Supported Benchmark before submittion!"
                );
                errorChecking = true;
            }
            if (!dataFilename) {
                toast.error("Please choice Data File .json for submittion!");
                errorChecking = true;
            } else if (!dataFilename.endsWith(".json")) {
                toast.error("Please choice Data File .json for submittion!");
                errorChecking = true;
            }
            if (errorChecking) return;

            // Have data in local storage
            if (
                getLocalStorage(LOCAL_STORAGE_TASK) === selectedTask &&
                getLocalStorage(LOCAL_STORAGE_BENCHMARK) ===
                    selectedBenchmark &&
                getLocalStorage(LOCAL_STORAGE_FULLNAME_PATH) === fullnamePath
            ) {
                setCurrentIndex(currentIndex + 1);
                setDisableCombobox(true);
            }
            // Dont have data in local storage
            else {
                // Update UI
                setUploadState(true);

                // select API
                let evalAPI = "";
                switch (selectedTask) {
                    case SEMANTIC_TEXTUAL_SIMILARITY_TASK:
                        evalAPI = STS_BENCHMARK_POST_URL;
                        break;
                    case TEXT_CLASSIFICATION_TASK:
                        evalAPI = TEXT_CLS_BENCHMARK_POST_URL;
                        break;
                    case TOKEN_CLASSIFICATION_TASK:
                        evalAPI = TOKEN_CLS_BENCHMARK_POST_URL;
                        break;
                    default:
                        evalAPI = "";
                        break;
                }

                if (evalAPI) {
                    let formData = new FormData();
                    formData.append("predict_result", fileBlob);
                    axios
                        .post(evalAPI, formData, {
                            params: {
                                supported_benchmark: selectedBenchmark,
                            },
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.status === 200) {
                                if (res.data._code)
                                    toast.error(
                                        `Check file ${dataFilename} against! Don't satify the data format.`
                                    );
                                else {
                                    toast.success(
                                        `Evaluate file ${dataFilename} in task ${selectedTask} SUCCESSFULLY!`
                                    );

                                    setJsonData(res.data);
                                    setCurrentIndex(currentIndex + 1);
                                    setDisableCombobox(true);

                                    // Update local storage
                                    saveLocalStorage(
                                        LOCAL_STORAGE_TASK,
                                        selectedTask
                                    );
                                    saveLocalStorage(
                                        LOCAL_STORAGE_BENCHMARK,
                                        selectedBenchmark
                                    );
                                    saveLocalStorage(
                                        LOCAL_STORAGE_FULLNAME_PATH,
                                        fullnamePath
                                    );
                                }
                            } else {
                                toast.error(
                                    `Server Error! Upload evaluation file ${dataFilename} FAIL!`
                                );
                                setJsonData(null);
                            }

                            setUploadState(false);
                        })
                        .catch((error) => {
                            toast.error(
                                `Server Error! Upload evaluation file ${dataFilename} FAIL!`
                            );
                            setJsonData(null);
                            setUploadState(false);
                        });
                }
            }
        } else if (currentIndex === 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const previousButtonClick = () => {
        toast.dismiss();
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            if (currentIndex - 1 === 0) setDisableCombobox(false);
        }
    };

    return (
        <>
            <div
                style={{ fontSize: "24px" }}
                className="py-2 mb-0 border-gradient border-gradient-green border-only-bottom"
            >
                Step by Step Submission
            </div>

            {/* Step by step header */}
            <StepNavigation
                labelArray={labelArray}
                currentStep={currentIndex + 1}
                updateStep={setCurrentIndex}
            ></StepNavigation>

            <div
                className={
                    "d-flex px-5" +
                    (currentIndex !== 0
                        ? " justify-content-between"
                        : " justify-content-end")
                }
            >
                {currentIndex !== 0 && (
                    <Button
                        onClick={previousButtonClick}
                        style={{ width: "25%", background: "#0054a3" }}
                    >
                        Previous
                    </Button>
                )}

                <Button
                    onClick={nextButtonClick}
                    style={{ width: "25%", background: "#03AA6D" }}
                    value={currentIndex === 2 ? "submit" : "next"}
                >
                    {currentIndex === 2 ? "Submit" : "Next"}
                </Button>
            </div>

            <Carousel
                activeIndex={currentIndex}
                controls={false}
                indicators={false}
            >
                {/* Data Uploading Step 1 */}
                <Carousel.Item as="div" className="px-2">
                    <DataLoadStep1
                        setFileBlob={setFileBlob}
                        setDataFilename={setDataFilename}
                        uploadState={uploadState}
                        setFullnamePath={setFullnamePath}
                    />
                </Carousel.Item>

                {/* Review Result Step 2 */}
                <Carousel.Item as="div" className="px-2">
                    <ViewResultStep2 data={jsonData} />
                </Carousel.Item>

                {/* Add metadata and submit Step 3 */}
                <Carousel.Item as="div" className="px-2">
                    <MetadataFormStep3 ref={ref} />
                </Carousel.Item>
            </Carousel>
        </>
    );
};

export default StepByStepSubmission;
