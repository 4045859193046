import { useMatch, useResolvedPath } from "react-router-dom";
import { FaHome, FaDatabase, FaTasks, FaTrophy } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const CustomLink = ({ to, children, ...props }) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <li className={isActive ? "active" : ""}>
            <LinkContainer to={to} {...props}>
                <Nav.Link>{children}</Nav.Link>
            </LinkContainer>
        </li>
    );
};

const NavbarLayer = () => {
    return (
        <Navbar
            expand="lg"
            className="nav-custom py-lg-0 px-lg-3 py-md-2 px-md-3 py-sm-2 py-2 sticky-top"
            collapseOnSelect
        >
            <Container className="m-0">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="m-0">
                    <Nav className="me-auto">
                        <CustomLink to="/">
                            <FaHome /> &nbsp; Overview
                        </CustomLink>
                        <CustomLink to="/data">
                            <FaDatabase /> &nbsp; Data
                        </CustomLink>
                        <CustomLink to="/submission">
                            <FaTasks /> &nbsp;Submission
                        </CustomLink>
                        <CustomLink to="/leaderboard">
                            <FaTrophy /> &nbsp;Leaderboard
                        </CustomLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarLayer;
