import { useState } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import axios from "axios";

import { reformatCamelText } from "../../utils/stringUtils";
import CardDataModal from "./CardDataModal";
import { DATA_DOWNLOAD_URL } from "../../contexts/constants";
import { saveJsonFile } from "../../utils/fileUtils";

const CardComponent = ({ info }) => {
    // local state
    const [showModal, setShowModal] = useState(false);
    const [downloadState, setDownloadState] = useState(false);

    // event download
    const downloadInfo = () => {
        toast.dismiss();
        setDownloadState(true);

        // Call API
        axios
            .get(DATA_DOWNLOAD_URL, {
                params: {
                    task: info.task ? info.task : "",
                    benchmark_name: info.name ? info.name : "",
                },
            })
            .then((res) => {
                setDownloadState(false);
                saveJsonFile(
                    `${info.name ? info.name : "dataset_info"}.json`,
                    res.data
                );

                toast.success(
                    `Download ${
                        info.name ? info.name : ""
                    } dataset SUCCESSFULLY!`
                );
            })
            .catch((error) => {
                setDownloadState(false);
                toast.error(
                    `Download ${info.name ? info.name : ""} dataset FAIL!`
                );
            });
    };

    // Event show modal
    const showDetailInfo = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-lg-3 mb-md-3 mb-sm-3 mb-3">
                <div className="card">
                    {/* HEADER */}
                    <div className="card-header" onClick={showDetailInfo}>
                        <div className="card-title">
                            {reformatCamelText(
                                info.title ? info.title : info.task
                            )}
                        </div>
                        <div className="green-dot"></div>
                    </div>

                    <div className="divider"></div>

                    {/* CONTENT */}
                    <div className="card-content" onClick={showDetailInfo}>
                        <p>Name: {info.name}</p>
                        <p>Version: {info.name}</p>
                        <p>Task: {info.task}</p>
                    </div>

                    <div className="card-footer">
                        <div className="d-flex align-items-center">
                            {downloadState && (
                                <Spinner
                                    animation="border"
                                    variant="info"
                                    className="mx-3 "
                                />
                            )}
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>{`Download ${
                                        info.name ? info.name : ""
                                    } dataset`}</Tooltip>
                                }
                            >
                                <div
                                    className="download-icon"
                                    onClick={downloadInfo}
                                    alt="download"
                                ></div>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>

            {/* SHOW MODAL */}
            {showModal && <CardDataModal info={info} />}
        </>
    );
};
export default CardComponent;
